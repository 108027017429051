@import "../components/utilities/variables.scss";

.App {
    display: flex;
    flex-direction: column;
    //for footer positioning - MAKE DYNAMIC!!!
    padding-bottom: 41px;
}

.Content {
    padding: 10px;
    //background-color: blue;

    @media #{$mq-small} {
        margin: 0 4%;
    }
    @media #{$mq-medium} {
        margin: 0 8%;
    }
    @media #{$mq-large} {
        margin: 0 12%;
    }
    @media #{$mq-xlarge} {
        margin: 0 16%;
    } 
    @media #{$mq-xxlarge} {
        margin: 0 20%;
    }
}

::-webkit-scrollbar {
    //width: 10px;
    display: none;
}

::-webkit-scrollbar-track {
    background: #333;
}

::-webkit-scrollbar-thumb {
    background: gray;
}

::-webkit-scrollbar-track:hover {
    background: blue;
}