@import '../utilities/variables.scss';

.NavPositioning {
    //flex: 1;
    display: flex;
    flex-direction: row;
    //flex-wrap: wrap;
    align-items: center;
    //background-color: green;
    
}

.NavContent {
    display: flex;
  flex: 1;
  flex-direction: row;
  align-items: right;
  white-space: nowrap;

  padding: 10px;

  @media #{$mq-small} {
    margin: 0 4%;
  }
  @media #{$mq-medium} {
    margin: 0 8%;
  }
  @media #{$mq-large} {
    margin: 0 12%;
  }
  @media #{$mq-xlarge} {
    margin: 0 18%;
  } 
  @media #{$mq-xxlarge} {
    margin: 0 25%;
  }
}
a {
  text-decoration: none;
}
a:hover {
  color: blue;
}

.TopBarLeft {
    flex: 1;
    text-align: left;
    //padding-left: 6px;
    overflow: visible;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.TopBarCenter {
    flex: 1;
    // text-align: center;
    // overflow: visible;
    // white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.TopBarRight {
    flex: 1;
    overflow: visible;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.SideBarToggle {
  //visibility: hidden;
  display: none;
}

@media screen and (max-width: 500px) {
  .TopBar {
      flex-direction: column;
      padding: 0px;
  }
  .TopBarRight {
      flex-direction: column;
      //flex-wrap: wrap;
      padding: 0px;
      display: none;
  }
  .SideBarToggle {
    //visibility: visible;
    display: flex;
  }
}
  // @media screen and (min-width: 500px) {
  //   .SideBarToggle {
  //     visibility: hidden;
  //   }
  // }