@import '../utilities/variables.scss';

.HomeContent {
    //flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    //background-color: green;
    
}

.TopBarLeft {
    flex: 1;
   // text-align: left;
    //padding-left: 6px;
    //overflow: visible;
    //white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}


.HomeRightContent {
    flex: 1;
    overflow: visible;
    //white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    
    //padding-bottom: 40%;
    // margin: 0 0 $base-spacing*2;
    // background-image: url(../../assets/images/HolmesProfileSm.jpeg);
    // background-position: center center;
    // background-size: cover;
  
    // @media #{$mq-xlarge} and #{$mq-retina} {
    //   background-image: url(../../assets/images/HolmesProfileLg.jpeg);
    // }
  
    // @media #{$mq-medium} {
    //   background-image: url(../../assets/images/HolmesProfileSm.jpeg);
    // }
  
    // @media #{$mq-medium} and #{$mq-retina} {
    //   background-image: url(../../assets/images/HolmesProfileSm.jpeg);
    // }
  
    // Additional media queries
}

//https://css-tricks.com/dont-overthink-flexbox-grids/
@media (max-width: 600px) {
  .HomeContent {
    display: block;
  }
}

// .detail {
//     height: 0;
//     padding-bottom: 40%;
//     margin: 0 0 $base-spacing*2;
//     background-image: url(../../assets/images/HolmesProfileSm.jpeg);
//     background-position: center center;
//     background-size: cover;
  
//     @media #{$mq-xlarge} and #{$mq-retina} {
//       background-image: url(../../assets/images/HolmesProfileLg.jpeg);
//     }
  
//     @media #{$mq-medium} {
//       background-image: url(../../assets/images/HolmesProfileSm.jpeg);
//     }
  
//     @media #{$mq-medium} and #{$mq-retina} {
//       background-image: url(../../assets/images/HolmesProfileSm.jpeg);
//     }
  
//     // Additional media queries
  
//   }