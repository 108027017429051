@import "../utilities/variables.scss";

.footerPosition { 
  // flex-shrink: 0;
  // text-align: center;
  // background-color: tomato;
  // color: white;
  // flex-shrink: 0;
  //flex: 1;
  

  background-color: black;
  position: absolute;
  bottom: 0;
  width: calc(100%);
  //height: 41px;
}

.footerContent {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: right;
  white-space: nowrap;
  @media #{$mq-small} {
    margin: 0 4%;
  }
  @media #{$mq-medium} {
    margin: 0 8%;
  }
  @media #{$mq-large} {
    margin: 0 12%;
  }
  @media #{$mq-xlarge} {
    margin: 0 18%;
  } 
  @media #{$mq-xxlarge} {
    margin: 0 25%;
  }
}

.TopBarLeft {
  flex: 1;
  //text-align: left;
  overflow: visible;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.TopBarRight {
  flex: 1;
  //overflow: visible;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
