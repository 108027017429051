@import "./components/utilities/variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scrollbar-width: none;
  -ms-overflow-style: none;

  position: relative;
  min-height: 100vh;

  @media #{$mq-small} {
    font-size: $base-font-size*1.1;
  }
  @media #{$mq-medium} {
    font-size: $base-font-size*1.2;
    line-height: $base-line-height*1.2;
  }
  
  @media #{$mq-large} {
    font-size: $base-font-size*1.3;
  }
  
  @media #{$mq-xlarge} {
    font-size: $base-font-size*1.4;
  }
  
  @media #{$mq-xxlarge} {
    font-size: $base-font-size*1.6;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}