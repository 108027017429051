.SideBar {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 100%;
    background-color: rgba(#333, .87);
}
a {
    text-decoration: none;
    
  }
  a:hover {
    color: blue;
  }
.SideBarContent {
    display: flex;
    flex-direction: column;
    padding: 10px;
}